<template>
  <v-card>
    <v-card-title>
      House Manager
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="hosts"
        :loading="loading_host"
      >
        <template #item.actions="{item}">
          <v-btn
            icon
            @click="editHost(item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="listUser(item.id)"
          >
            <v-icon>
              mdi-account-multiple
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary"
        @click="newHost()"
      >
        Create Host
      </v-btn>
    </v-card-actions>
    <HostDetailDialog
      :visible="dialog_hostdetail"
      :value="selected_host"
      @close="dialog_hostdetail = false"
      @reload="loadHosts"
    />
    <UserDialogDatatable
      :visible="dialog_users"
      :host-id="selected_host_id"
      @close="dialog_users = false"
      @reload="loadHosts"
    />

  </v-card>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import HostDetailDialog from '../components/OG_Management/HostDetailDialog'
import Host from '../types/Host'
import UserDialogDatatable from '../components/OG_Management/UserDialogDatatable'
import hostService from '@/services/Host.service'

export default {
  name: 'HouseManagement',
  components: {
    UserDialogDatatable,
    HostDetailDialog
  },
  data: () => ({
    hosts: [],
    loading_host: false,
    items: [],
    dialog_users: false,
    dialog_hostdetail: false,
    selected_host: {},
    selected_host_id: null,
    headers: [
      {
        text: 'Host',
        value: 'name'
      },
      {
        text: 'Description',
        value: 'description',
        sortable: false
      },
      {
        text: '',
        value: 'actions'
      }
    ]
  }),

  computed: {},
  mounted() {
    this.loadHosts()
  },
  methods: {
    async loadHosts() {
      this.loading_host = true
      this.hosts = await hostService.loadHosts()
      this.loading_host = false
    },

    editHost(host) {
      this.selected_host = host
      this.dialog_hostdetail = true
    },

    newHost() {
      this.selected_host = new Host()
      this.dialog_hostdetail = true
    },

    listUser(id) {
      this.selected_host_id = id
      this.dialog_users = true
    }
  }
}
</script>

<style scoped>

</style>
