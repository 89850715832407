<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        Host
      </v-card-title>
      <v-card-text>
        <v-form ref="Host">
          <v-text-field
            v-model="data.name"
            label="Name"
            :rules="[rules.required, rules.maxLength(50)]"
          />
          <v-text-field
            v-model="data.description"
            label="Description"
            :rules="[rules.required, rules.maxLength(500)]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          small
          color="primary"
          :loading="loaders.detail"
          @click="save"
        >
          Speichern
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import _ from 'lodash'

export default {
  name: 'HostDetailDialog',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    value: {
      type: Object,
      default: Object,
      get(value) {
        console.log('detail', value)
        this.data = value
      },
    }
  },
  data() {
    return {
      data: {},
      rules: {
        required: (value) => {
          return !!value || 'required.'
        },
        maxLength: (length) => {
          return (value) => {
            return ((value?.length || 0) <= length) || `max ${length} characters.`
          }
        },
      }
    }
  },
  computed: {
    ...mapState('hosts', ['loaders'])
  },
  watch: {
    visible(val) {
      console.log('Visible changed', val)
      this.data = _.clone(this.value)
    }
  },
  methods: {
    ...mapActions('hosts', ['saveHost']),
    ...mapMutations('hosts', ['setDialogsDetail']),
    async save() {
      if (!this.$refs.Host.validate()) {
        return
      }
      await this.saveHost(this.data)
      this.$emit('reload')
      this.$emit('close')
    },
    close() {
      this.$emit('close')
    }
  },
}
</script>

<style scoped>

</style>
